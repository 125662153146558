import React from 'react';
import moment from 'moment';
import { Button, Space, Tooltip } from 'antd';
import {
  BarsOutlined,
  CheckCircleFilled,
  CheckOutlined,
  CloseOutlined,
  ContactsOutlined,
  EditOutlined,
  FormOutlined,
  QrcodeOutlined,
  SettingOutlined,
  TeamOutlined,
  UnlockOutlined,
  UnorderedListOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';

const date = moment().toISOString();

export const listDash = [
  {
    id: 'signature',
    name: 'Signatures',
    children: [
      {
        id: 2,
        title: 'Signature List',
        subTitle: 'Manage your signature',
        href: '/signatureList',
        icon: <UnorderedListOutlined />,
      },
      {
        id: 1,
        title: 'Signature',
        subTitle: 'Create a new signature',
        href: '/signatureCreate',
        icon: <FormOutlined />,
      },
    ],
  },
  {
    id: 'template',
    name: 'Signatures Templates',
    children: [
      {
        id: 4,
        title: 'Template List',
        subTitle: 'Manage your templates',
        href: '/signatureTemplateList',
        icon: <BarsOutlined />,
      },
      {
        id: 3,
        title: 'Template',
        subTitle: ' Create a new template',
        href: '/signatureTemplateCreate',
        icon: <EditOutlined />,
      },
    ],
  },
  {
    id: 'people',
    name: 'People',
    children: [
      {
        id: 5,
        title: 'People',
        subTitle: 'List all people',
        href: '/entity/person',
        icon: <TeamOutlined />,
      },
      {
        id: 6,
        title: 'Shared Contacts',
        subTitle: 'Manage your shared contacts',
        href: '/entity/person',
        icon: <ContactsOutlined />,
      },
    ],
  },
];

// menu create Signature
export const listMenuSignature = [
  {
    id: 'information',
    title: 'General Information',
    subTitle: 'General Information',
    icon: <QrcodeOutlined />,
    active: true,
  },
  {
    id: 'choosePeople',
    title: 'Choose People',
    subTitle: 'Choose People',
    icon: <UserOutlined />,
    active: false,
  }, //
  // {
  //   id: 'advanceConfig',
  //   title: 'Advance Configure',
  //   subTitle: 'Advance Configure',
  //   icon: <SettingOutlined />,
  //   active: false,
  // },
];

// menu create Out Office
export const listMenuOutOffice = [
  {
    id: 'information',
    title: 'General Information',
    subTitle: 'General Information',
    icon: <QrcodeOutlined />,
    active: true,
  },
  {
    id: 'choosePeople',
    title: 'Choose People',
    subTitle: 'Choose People',
    icon: <UserOutlined />,
    active: false,
  },
  {
    id: 'settings',
    title: 'Configure',
    subTitle: 'Advance Configure',
    icon: <SettingOutlined />,
    active: false,
  },
];

// menu settings
export const settingsList = [
  {
    id: 'profile',
    title: 'Profile',
    subTitle: 'View or edit your profile',
    icon: <UserOutlined />,
    active: true,
  },
  {
    id: 'general',
    title: 'General',
    subTitle: 'Configure your account',
    icon: <SettingOutlined />,
    active: false,
  },
  {
    id: 'administrators',
    title: 'Administrators',
    subTitle: 'Manage and sync your collaborators',
    icon: <TeamOutlined />,
    active: false,
  },
  {
    id: 'subscription',
    title: 'Subscription',
    subTitle: 'Subscription Information',
    icon: <CheckCircleFilled />,
    active: false,
  },
];

// menu subscription
export const subscriptionList = [
  {
    id: 'subscription',
    title: 'Subscription',
    subTitle: 'View or edit your subscription',
    icon: <CheckCircleFilled />,
    active: true,
  },
  {
    id: 'upgradePlan',
    title: 'Upgrade Plan',
    subTitle: 'Upgrade your subscription',
    icon: <UnlockOutlined />,
    active: false,
  },
];

// menu profile
export const profileList = [
  {
    id: 'general-information',
    title: 'General Information',
    subTitle: 'View or edit the Organization, Summary and IMS',
    icon: <CheckCircleFilled />,
    active: true,
  },
  {
    id: 'advance-information',
    title: 'Advance Information',
    subTitle: 'View or edit the Emails, Links and Phone Numbers',
    icon: <UnlockOutlined />,
    active: false,
  },
];

export const columnsSelect = (func: any, isRemoved: boolean, Pop?: any) => [
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: (a: any, b: any) => a.name.length - b.name.length,
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    render: (text: string) => {
      if (text === 'PERSON')
        return (
          <Space size="middle">
            <UserOutlined title={text} />
          </Space>
        );
      else if (text === 'GROUP') return <TeamOutlined title={text} />;
      else return <UserSwitchOutlined title={text} />;
    },
  },
  {
    title: 'Action',
    render: (text: any, record: any) => (
      <Space size="middle">
        {isRemoved ? (
          <Button
            type="text"
            onClick={(event) => {
              event.stopPropagation();
              func(record);
            }}
            icon={
              <Tooltip title={`Remove ${record.name}`}>
                <CloseOutlined className="signatures__table-close" />
              </Tooltip>
            }
          />
        ) : !!Pop ? (
          <Pop
            title={
              <>
                <b>Are you sure to apply this signature?</b>
                <div>The corresponding licenses are used</div>
              </>
            }
            onConfirm={() => {
              // event.stopPropagation()
              func(record);
            }}
            okText="Yes"
            cancelText={'No'}
          >
            <Button
              type="text"
              icon={<CheckOutlined className="signatures__table-check" />}
            />
          </Pop>
        ) : (
          <Button
            type="text"
            onClick={() => func(record)}
            icon={<CheckOutlined className="signatures__table-check" />}
          />
        )}
      </Space>
    ),
  },
];

export const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: (a: any, b: any) => a.name.length - b.name.length,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Action',
    key: 'action',
    render: (text: any, record: any) => (
      <Space size="middle">
        <Button
          type="text"
          icon={
            <Tooltip title={`Remove ${record.name}`}>
              <CloseOutlined className="signatures__table-close" />
            </Tooltip>
          }
        />
      </Space>
    ),
  },
];

export const dataSignatures = [
  {
    key: '1',
    name: 'New signature - todos los campos',
    automatically: false,
    frequency: 'MONTHLY',
    create_at: date,
    update_at: date,
  },
  {
    key: '2',
    name: 'Firma nueva Sur 2018',
    automatically: false,
    frequency: 'QUARTERLY',
    create_at: date,
    update_at: date,
  },
  {
    key: '3',
    name: 'Firma Chile',
    automatically: false,
    frequency: 'MONTHLY',
    create_at: date,
    update_at: date,
  },
  {
    key: '4',
    name: 'Administración termodinamica',
    automatically: false,
    frequency: 'MONTHLY',
    create_at: date,
    update_at: date,
  },
  {
    key: '5',
    name: 'Pasantes',
    automatically: true,
    frequency: 'QUARTERLY',
    create_at: date,
    update_at: date,
  },
  {
    key: '6',
    name: 'New signature',
    automatically: true,
    frequency: 'QUARTERLY',
    create_at: date,
    update_at: date,
  },
];

export const dataPeoplesSelect = [
  {
    key: '1',
    name: 'John Brown',
    age: 32,
    email: 'jhon@aeegle.com',
  },
  {
    key: '2',
    name: 'Jim Green',
    age: 42,
    email: 'jim@aeegle.com',
  },
  {
    key: '3',
    name: 'Joe Black',
    age: 32,
    email: 'joe@aeegle.com',
  },
];

export const dataPeoples = [
  {
    key: '1',
    name: 'John Brown',
    age: 32,
    email: 'jhon@aeegle.com',
  },
  {
    key: '2',
    name: 'Jim Green',
    age: 42,
    email: 'jim@aeegle.com',
  },
];

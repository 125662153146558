import React, { CSSProperties, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';

const CardDash = (props: IProps) => {
  const history = useHistory();

  const {
    title,
    subTitle,
    icon,
    id,
    active = false,
    onClick = (id: any) => console.log(!!id),
    style = {},
  } = props;

  return (
    <div className={`card ${active ? 'active' : ''}`} style={{ ...style }}>
      <div
        className="card-wrapper"
        onClick={() => {
          if (id.toString() == 'subscription') {
            history.push('/accountProfile');
            return;
          }
          onClick(id);
        }}
      >
        <div className="card-body-content">
          <div className="title">{title}</div>
          {!Array.isArray(subTitle) ? (
            <div className="sub-title">{subTitle}</div>
          ) : (
            subTitle.map((sub, i) => (
              <div key={i} className="sub-title">
                • {sub}
              </div>
            ))
          )}
        </div>
        <div className="icon">{icon}</div>
      </div>
    </div>
  );
};

interface IProps {
  title?: string;
  subTitle: string | string[];
  id: number;
  icon: ReactNode;
  active?: boolean;
  onClick?: (id: any) => void;
  style?: CSSProperties;
}

export default CardDash;

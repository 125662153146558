import v1 from 'config/axios/v1.instance';
import apiV1 from 'config/axios/v1.instance';
import { initSignatureList } from '../initialStates';
import { useCallback, useEffect, useState } from 'react';
import {
  IPoliciesModel,
  ISignatureListModel,
} from 'models/api/signature.model';
import { createSignatureData } from 'redux/initialState/form-initial-state';

// data cleaning, as api fails :'(
const formatSignature = (data: any) => {
  let newData = {};
  Object.keys(data).forEach(
    (key) =>
      createSignatureData.hasOwnProperty(key) &&
      (newData = { ...newData, [key]: data[key] }),
  );
  return newData;
};

// utils format only payload
const formatPayloadSignature = (body: any) => {
  // remove reference at state redux
  const payload = { ...body };
  // get array of string for new or edit signature
  payload.entities = payload.entities.map((e: any) => {
    return e.id;
  });
  // set entities array id
  // payload.entities = entities;
  payload.primary_signature = payload.primary_signature ? 1 : 0;
  payload.active = payload.active ? 1 : 0;
  return payload;
};

// List signature
export const useFetchSignatureList = (page: number = 1) => {
  const [data, setData] = useState<ISignatureListModel>(initSignatureList);
  const [state, setState] = useState({ loading: true, error: null });

  // get data server api
  const getDataServer = useCallback(() => {
    apiV1
      .get<ISignatureListModel>(`/signatures?page=${page}&sort=id,desc`)
      .then(({ data }) => {
        setData(data);
        setState((prev) => ({ ...prev, loading: false }));
      })
      .catch((err) => {
        setState((state) => ({
          ...state,
          loading: false,
          error: !!err?.response ? err?.response?.data : err?.message,
        }));
      });
  }, [page]);

  useEffect(() => getDataServer(), [page, getDataServer]);

  // return state
  return { data, ...state, refresh: getDataServer };
};

// Get By id signature
export const getByIdSignature = (id: number) => {
  return v1
    .get(`/signatures/${id}`)
    .then(({ data }) => {
      return { data, err: false };
    })
    .catch((err) => ({
      data: null,
      err: !!err?.response ? err?.response?.data : err?.message,
    }));
};

// Get By id entities
export const getByIdEntities = (id: number) => {
  return v1
    .get(`/signatures/${id}/entities`)
    .then(({ data }) => {
      return { data, err: false };
    })
    .catch((err) => ({
      data: null,
      err: !!err?.response ? err?.response?.data : err?.message,
    }));
};

// New signature.
export const createSignature = (beforeBody: any) => {
  const payload = formatPayloadSignature(beforeBody);
  const body = formatSignature(payload);
  return v1
    .post('/signatures', body)
    .then(({ data }) => ({ data, err: false }))
    .catch((err) => ({
      data: null,
      err: !!err?.response ? err?.response?.data : err?.message,
    }));
};

// Update Signature
export const editSignature = (beforeBody: any, id: number) => {
  const payload = formatPayloadSignature(beforeBody);
  const body = formatSignature(payload);
  return v1
    .put(`/signatures/${id}`, body)
    .then(({ data }) => ({ data, err: false }))
    .catch((err) => ({
      data: null,
      err: !!err?.response ? err?.response?.data : err?.message,
    }));
};

// Apply signature
export const applySignature = async (id: number, body: any) => {
  console.log(body);
  // save edit signature before
  await editSignature(body, id);
  if (!id) return { err: true, data: null };
  return v1
    .post(`/signatures/${id}/apply`)
    .then(({ data }) => ({ data, err: false }))
    .catch((err) => ({
      data: null,
      err: !!err?.response ? err?.response?.data : err?.message,
    }));
};

export const deleteSignature = (id: number) => {
  return v1
    .delete(`/signatures/${id}`)
    .then(({ data }) => ({ data, err: false }))
    .catch((err) => ({
      data: null,
      err: !!err?.response ? err?.response?.data : err?.message,
    }));
};

export const useFetchPolicies = () => {
  // definition state
  const [data, setData] = useState<IPoliciesModel>(initSignatureList);
  const [state, setState] = useState({ loading: true, error: null });

  useEffect(() => {
    apiV1
      .get<IPoliciesModel>(`/policies`)
      .then(({ data }) => {
        setData(data);
        setState((prev) => ({ ...prev, loading: false }));
      })
      .catch((err) => {
        setState((state) => ({
          ...state,
          loading: false,
          error: !!err?.response ? err?.response?.data : err?.message,
        }));
      });
  }, []);

  // return state
  return { data, ...state };
};

import { Button, message, Modal, Typography } from 'antd';
import { deleteCustomer } from 'api/authAndCustomer/actionsCustomer.api';
import logout from 'config/google/logout';
import { useDispatch } from 'react-redux';
import { useGoogleApi } from 'react-gapi';
import { scopes } from 'config/google/scopes';
import { useState } from 'react';
import useStateRedux from 'hooks/useStateRedux';

const UpgradeSub = () => {
  // @ts-ignore
  const gapi = useGoogleApi({ scopes });
  const auth = gapi?.auth2?.getAuthInstance();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { admin_full_name, admin_email, user_email }: any = useStateRedux(
    'auth',
  );
  const handleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const uninstall = () => {
    const key = 'uninstall';
    message.loading({ content: 'Deleting...', key });
    deleteCustomer()
      .then(async () => {
        message.success({
          content: 'customer deleted!!',
          duration: 3,
          key,
          onClose: async () => await logout(dispatch, auth),
        });
      })
      .catch(() => {
        message.error('error!! deleting customer');
      });
  };

  return (
    <>
      {admin_email == user_email && (
        <div className="card__create-form card__list-wrapper mb-5">
          <div className="mt-3" style={{ width: '100%' }}>
            <div className="mb-4">
              <Typography.Title style={{ fontWeight: 400 }} level={2}>
                Uninstall Account
              </Typography.Title>
              <Typography.Text>
                By deleting your account, you will lose access to all associated
                services and your data will be permanently deleted
              </Typography.Text>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button type="default" onClick={handleModal}>
                Uninstall
              </Button>
            </div>
          </div>
          <Modal
            bodyStyle={{ paddingBottom: 35 }}
            title="Uninstall Account"
            visible={isModalVisible}
            onCancel={handleModal}
            onOk={() => {
              uninstall();
              handleModal();
            }}
          >
            <h4>Are you sure your want to terminate your account?</h4>
            <div style={{ marginTop: '10px', display: 'grid' }}>
              <Typography.Text>Account name and organization:</Typography.Text>
              <Typography.Text>{`${admin_full_name} | ${admin_email}`}</Typography.Text>
              <Typography.Text style={{ marginTop: '10px' }}>
                Please note that by proceeding:
              </Typography.Text>

              <ul>
                <li>
                  This account and all sub-accounts related to it will be
                  permanently deleted.
                </li>
                <li>
                  You and any other users that have been previously invited will
                  no longer be able to access it.
                </li>
                <li>
                  Please make sure to{' '}
                  <a
                    href="https://documentation.mailjet.com/hc/en-us/articles/360042707414-How-to-export-your-data"
                    target="_blank"
                  >
                    export and back up your account data
                  </a>{' '}
                  if you wish to use it again. All campaigns, transactional
                  emails and automated emails as well as all saved templates and
                  contact lists, will be permanently deleted.
                </li>
                <li>
                  All your personal information will be removed from our system.
                </li>
              </ul>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};
export default UpgradeSub;

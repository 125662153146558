import apiV1 from 'config/axios/v1.instance';
import { IPreview } from 'models/api/signature.model';

export const getPreviewTemplateHTML = (body: { body: string; user: string }) =>
  apiV1
    .post<IPreview>(`/signatures/previews`, body)
    .then(({ data }) => {
      return { data, error: false };
    })
    .catch((err) => {
      console.log(err);
      return { data: null, error: true };
    });

import { message, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import CurrentFormProfile from './components';
import useStateRedux from 'hooks/useStateRedux';
import React, { useEffect, useState } from 'react';
import Loading from 'components/loading/loading';
import { profileList } from 'helpers/listDashboard';
import ListDash from 'components/listDash/listDash';
import { handleEditFormProfile } from 'redux/actions/forms.action';
import { ActionsSettings } from 'components/actionsForm/actionsForm';
import { useHistory } from 'react-router-dom';
import { updateProfileById } from 'api/person/person.api';
import { uiHandleLoading } from 'redux/actions/ui.action';

const { Title } = Typography;

const ProfilePage = (props: IProps) => {
  const dispatch = useDispatch();
  const { forms, ui }: any = useStateRedux();
  const [state, setState] = useState({
    list: profileList,
    active: 'general-information',
  });
  const history = useHistory();
  const { profile } = forms;
  const {
    match: {
      params: { id },
    },
  } = props;

  function addTypeValue(array: any) {
    array.forEach(
      (obj: { hasOwnProperty: (arg0: string) => any; type: string }) => {
        if (!obj.hasOwnProperty('type')) {
          obj.type = 'work';
        }
      },
    );
    return array;
  }

  // click menu
  const onClick = (id: any) =>
    setState((prev) => {
      const list = prev.list.map((i) =>
        i.id === id ? { ...i, active: true } : { ...i, active: false },
      );
      return { ...prev, list, active: id };
    });

  const onFinish = async () => {
    const payload = {
      organizations: addTypeValue(profile.organizations),
      emails: addTypeValue(profile.emails),
      websites: profile.websites,
      phones: profile.phones,
    };

    dispatch(uiHandleLoading());
    const success = await updateProfileById(profile.id, payload);
    if (!success) message.error('Failed to update profile');
    else {
      message.success('Update successful');
      history.push('/entity/person');
    }
    dispatch(uiHandleLoading());
  };

  useEffect(() => {
    // (if) Launch edit form template
    // (else) Reset clean form
    !!id && dispatch(handleEditFormProfile(id));
  }, [dispatch, id]);

  if (ui.loading) return <Loading />;

  return (
    <div
      className="animate__animated animate__fadeIn"
      style={{ minHeight: '75vh', position: 'relative' }}
    >
      {/*Title name*/}
      <Title level={1} style={{ marginBlockEnd: 0 }}>
        {profile.name.fullName}
      </Title>

      {/*Action Buttons*/}
      <div className="view-desktop">
        <ActionsSettings
          onClick={onFinish}
          onCancel={() => history.push('/entity/person')}
        />
      </div>

      <ListDash state={state.list} onClick={onClick} title="Choose step" />

      {/*Form Row*/}
      <div>
        <CurrentFormProfile current={state.active} />
      </div>
    </div>
  );
};

interface IProps {
  match: { params: { id: string | undefined } };
  history: any;
  location: any;
}

export default ProfilePage;

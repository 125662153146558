import React, { useEffect, useState } from 'react';
import { Menu, message } from 'antd';
import { useDispatch } from 'react-redux';
import { useGoogleApi } from 'react-gapi';
import logout from 'config/google/logout';
import { scopes } from 'config/google/scopes';
import useStateRedux from 'hooks/useStateRedux';
import { Link, useLocation } from 'react-router-dom';
import {
  CheckCircleFilled,
  DeleteOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { deleteCustomer } from 'api/authAndCustomer/actionsCustomer.api';

const menu = (brand?: boolean) => {
  // @ts-ignore
  const gapi = useGoogleApi({ scopes });
  const auth = gapi?.auth2?.getAuthInstance();
  const dispatch = useDispatch();
  const { user_full_name, email, admin_email, user_email }: any = useStateRedux(
    'auth',
  );
  const location = useLocation();
  const [showUninstall, setShowUninstall] = useState(false);

  useEffect(() => {
    if (admin_email != user_email) return;
    setShowUninstall(location.pathname === '/accountProfile');
  }, [location]);

  const onSingOut = () => {
    return logout(dispatch, auth);
  };

  const uninstall = () => {
    const key = 'uninstall';
    message.loading({ content: 'Deleting...', key });
    deleteCustomer()
      .then(async () => {
        message.success({
          content: 'customer deleted!!',
          duration: 3,
          key,
          onClose: async () => await logout(dispatch, auth),
        });
      })
      .catch(() => {
        message.error('error!! deleting customer');
      });
  };

  return (
    <Menu className="aside__menu-menu">
      <Menu.Item key="0">
        <p>
          <b>{user_full_name}</b>
        </p>
        <p>{email}</p>
      </Menu.Item>
      {!brand && (
        <>
          <Menu.Item key="1">
            <Link to="/setting">
              <SettingOutlined style={{ color: '#72757a' }} />{' '}
              <span>Settings</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="2">
            <Link to="/accountProfile">
              <CheckCircleFilled style={{ color: '#72757a' }} />{' '}
              <span>Subscription</span>
            </Link>
          </Menu.Item>
          {showUninstall && (
            <Menu.Item key="3" onClick={uninstall}>
              <DeleteOutlined style={{ color: '#72757a' }} />{' '}
              <span>Uninstall</span>
            </Menu.Item>
          )}
        </>
      )}
      <Menu.Divider />
      <Menu.Item key="4" className="text-center" onClick={onSingOut}>
        <span className="text-danger">Sign out</span>
      </Menu.Item>
    </Menu>
  );
};

export default menu;

import { useState } from 'react';
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { Filter, IItems } from './queryInterface';

const fieldLogic = ['AND', 'OR'];
const fieldOptions: IItems[] = [
  {
    value: 'Name',
    key: 'name',
  },
  {
    value: 'Email',
    key: 'email',
  },
  // {
  //   value: 'Is admin',
  //   key: 'isAdmin',
  // },
  {
    value: 'Organization Unit',
    key: 'org_unit_path',
  },
  {
    value: 'Department',
    key: 'department',
  },
  {
    value: 'Title',
    key: 'title',
  },
  {
    value: 'Signature',
    key: 'signature',
  },
  {
    value: 'Enabled',
    key: 'suspended',
  },
];
const conditionOptions = [
  {
    value: 'Contains',
    key: 'like',
  },
  {
    value: 'Equals',
    key: 'is',
  },
  {
    value: 'Not equals',
    key: 'ne',
  },
  {
    value: 'In',
    key: 'in',
  },
  {
    value: 'Not in',
    key: 'notIn',
  },
];

const QueryBuilder = ({ visible, handleModal, handleQuerySearch }: IProps) => {
  const [groupOp, setGroupOp] = useState<string>('AND');

  const [filters, setFilters] = useState<Filter[]>([
    { field: 'name', op: 'like', data: '' },
  ]);

  const handleAddFilter = () => {
    setFilters([
      ...filters,
      { field: fieldOptions[0].key, op: conditionOptions[0]!.key, data: '' },
    ]);
  };

  const handleRemoveFilter = (index: number) => {
    setFilters(filters.filter((_, i) => i !== index));
  };

  const handleFilterChange = (
    index: number,
    key: keyof Filter,
    value: string,
  ) => {
    const updatedFilters = [...filters];
    updatedFilters[index][key] = value;
    setFilters(updatedFilters);
  };

  const handleSubmit = () => {
    const querySearch = {
      groupOp: groupOp,
      rules: filters,
      formattedFilters: formatQuery(groupOp, filters),
    };
    handleQuerySearch(querySearch);
  };

  return (
    <Modal
      bodyStyle={{ paddingBottom: 35 }}
      title="Search..."
      visible={visible}
      onCancel={() => handleModal(false)}
      onOk={handleSubmit}
    >
      <Form>
        <Select value={groupOp} className="ml-1" onChange={setGroupOp}>
          {fieldLogic.map((field) => (
            <Select.Option key={field} value={field}>
              {field}
            </Select.Option>
          ))}
        </Select>
        <Button
          type="dashed"
          onClick={handleAddFilter}
          style={{ marginTop: 16 }}
          className="ml-1"
        >
          + Add Filter
        </Button>

        <Row gutter={[16, 16]} className="pt-1">
          {filters.map((filter, index) => (
            <Col span={24} key={index}>
              <Input.Group
                compact
                className="d-flex"
                style={{ gap: '10px', display: 'flex' }}
              >
                <Select
                  value={filter.field}
                  onChange={(value) =>
                    handleFilterChange(index, 'field', value)
                  }
                  style={{ width: '30%' }}
                >
                  {fieldOptions.map((field) => (
                    <Select.Option key={field.key} value={field.key}>
                      {field.value}
                    </Select.Option>
                  ))}
                </Select>

                <Select
                  value={filter.op}
                  onChange={(value) => handleFilterChange(index, 'op', value)}
                  style={{ width: '30%' }}
                >
                  {conditionOptions.map((condition) => (
                    <Select.Option key={condition?.key} value={condition!.key}>
                      {condition?.value}
                    </Select.Option>
                  ))}
                </Select>

                <Input
                  value={filter.data}
                  onChange={(e) =>
                    handleFilterChange(index, 'data', e.target.value)
                  }
                  style={{ width: '30%' }}
                />

                <Button
                  type="dashed"
                  shape="circle"
                  icon={<DeleteOutlined />}
                  onClick={() => handleRemoveFilter(index)}
                ></Button>
              </Input.Group>
            </Col>
          ))}
        </Row>
      </Form>
    </Modal>
  );
};

const formatQuery = (
  groupOp: string,
  filters: Filter[],
): string | Record<string, string> => {
  if (groupOp === 'OR') {
    // 'OR'
    const formattedFilters = filters
      .map((filter) => {
        const { field, op, data } = filter;
        return `${field}|${op}|${data}`; // "field|op|data"
      })
      .join(';');

    return formattedFilters;
  } else {
    // 'AND'"
    const formattedFilters: Record<string, string> = {};

    filters.forEach((filter) => {
      const { field, op, data } = filter;
      const filterValue = `${op}|${data}`; // "op|data"
      formattedFilters[field] = filterValue;
    });

    return formattedFilters; // {field: "op|data"}
  }
};

interface IProps {
  handleModal: (visible: boolean) => void;
  okClick?: (visible: boolean) => void;
  visible: boolean;
  handleQuerySearch: (querySearch: {
    groupOp: string;
    rules: Filter[];
    formattedFilters: Record<string, string> | string;
  }) => void;
}

export default QueryBuilder;

import apiV1 from 'config/axios/v1.instance';
import { initSignatureList } from '../initialStates';
import { useCallback, useEffect, useState } from 'react';
import { IEntityListModel } from 'models/api/signature.model';

export const useFetchEntityList = ({
  page = 1,
  type,
  search,
  available = false,
  limit,
}: IEntityParams) => {
  // definition state
  const [data, setData] = useState<IEntityListModel>(initSignatureList);
  const [state, setState] = useState({ loading: true, error: null });

  // get data server api
  const getDataServer = useCallback(() => {
    apiV1
      .get<IEntityListModel>(
        `/entities?page=${page}${available ? '&available=true' : ''}&limit=20${
          !!search ? `&or=email|like|${search};name|like|${search}` : ''
        }${!!type ? `&type=${type}` : ''}${!!limit ? `&limit=${limit}` : ''}`,
      )
      .then(({ data }) => {
        setData(data);
        setState((prev) => ({ ...prev, loading: false }));
      })
      .catch((err) => {
        setState((state) => ({
          ...state,
          loading: false,
          error: !!err?.response ? err?.response?.data : err?.message,
        }));
      });
  }, [page, search, available, type]);

  useEffect(() => {
    getDataServer();
  }, [getDataServer]);

  // return state
  return { data, ...state, refresh: getDataServer };
};

interface IEntityParams {
  page?: any;
  search?: any;
  available?: boolean;
  limit?: number;
  type?: 'PERSON' | 'GROUP' | '';
}

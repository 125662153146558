import React from 'react';
import menu from './menu';
import { assets } from 'helpers/assets';
import { Avatar, Dropdown } from 'antd';
import useStateRedux from 'hooks/useStateRedux';

interface IProps {
  brand?: boolean;
  avatar?: boolean;
}

const Navbar = ({ brand, avatar = true }: IProps) => {
  const { image, licenses, user_full_name, mailBox }: any = useStateRedux(
    'auth',
  );
  return (
    <div
      className="dashboard__navbar beauty-scroll"
      style={!!brand ? { justifyContent: 'space-between' } : {}}
    >
      {!!brand && (
        <div
          className="dashboard__aside-brand pointer"
          style={{ width: 'auto' }}
        >
          {/*<span>ZUMPUL</span>*/}
          <img src={assets.zumpul_brand} alt="Aeegle Inc." />
        </div>
      )}
      {avatar && (
        <Dropdown
          overlay={menu(brand)}
          trigger={['click']}
          className="aside__dropdown"
          arrow
          placement="bottomRight"
        >
          <div className="pointer dashboard__navbar-avatar">
            <Avatar src={image} />
            <div style={{ padding: '5px 0' }} className="text-center">
              <div
                className="ml-1"
                style={{
                  fontSize: '12px',
                  fontWeight: 500,
                  color: '#777777',
                  marginBlockEnd: 0,
                  lineHeight: '12px',
                }}
              >
                {user_full_name}
              </div>
              {!brand && (
                <span
                  className="ml-1 size-12"
                  style={{
                    fontWeight: 400,
                    color: '#777777',
                    lineHeight: '10px',
                  }}
                >
                  Available: {licenses - mailBox}
                </span>
              )}
            </div>
          </div>
        </Dropdown>
      )}
    </div>
  );
};

export default Navbar;

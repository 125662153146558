import { notification, Typography, Table, Button } from 'antd';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import NotData from 'components/result/notData';
import Loading from 'components/loading/loading';
import { syncPeople, useFetchPeopleList } from 'api/person/person.api';
import {
  HeaderAvatar,
  DepartmentElement,
  SuspendedElement,
} from 'components/card/cardListPeople';
import { ActionsNew } from 'components/actionsForm/actionsForm';
import useStateRedux from 'hooks/useStateRedux';
import { SearchOutlined } from '@ant-design/icons';
import QueryModalShare from '../../components/querySearch/modalShare';
import { Filter } from 'components/querySearch/queryInterface';
import { IPeopleFilters } from 'models/api/signature.model';

const { Title } = Typography;

const PeopleListPage = () => {
  const history = useHistory();
  const [filters, setFilters] = useState<IPeopleFilters>({
    page: 1,
  });
  const { data, loading } = useFetchPeopleList(filters);
  const { customer_id, mailbox }: any = useStateRedux('auth');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const syncPeopleFn = async () => {
    try {
      const mailBox = mailbox || 0;
      const stimatedSeconds = (() => {
        if (mailBox <= 50) return 60;
        if (mailBox <= 500) return 120;
        if (mailBox <= 1000) return 180;
        if (mailBox <= 5000) return 240;
        if (mailBox <= 10000) return 300;
        else return 360;
      })();

      const stimatedMinutes = stimatedSeconds / 60;
      const estimatedTime =
        Math.round(stimatedMinutes * 100) / 100 + ' Minutes';
      notification.info({
        message: 'User synchronization',
        description: (
          <span>
            User synchronization has started
            <br />
            reload again in about <b> {estimatedTime} </b>
          </span>
        ),
      });
      await syncPeople(customer_id);
    } catch (error) {}
  };
  const onClick = (payload: any) =>
    history.push(`/profile/${payload.id_person}`);
  const handlePagination = (pagination: any) =>
    setFilters({ page: pagination.current });

  const handleQuerySearch = (querySearch: {
    groupOp: string;
    rules: Filter[];
    formattedFilters: Record<string, string> | string;
  }) => {
    let filter = {};
    if (querySearch.groupOp == 'OR') {
      filter = {
        page: filters.page,
        ...(querySearch.rules[0] && { or: querySearch.formattedFilters }),
      };
    } else {
      filter = {
        page: filters.page,
        ...(querySearch.formattedFilters as Record<string, string>),
      };
    }
    setFilters(filter as IPeopleFilters);
    setIsModalVisible(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = (visible: boolean) => {
    setIsModalVisible(visible);
  };

  const pagination = {
    showSizeChanger: false,
    pageSize: 25,
    total: data.total_items,
    current: filters.page,
  };

  return (
    <div
      className="animate__animated animate__fadeIn"
      style={{ minHeight: '75vh', position: 'relative' }}
    >
      <Title level={1} style={{ marginBlockEnd: 0 }}>
        People List
      </Title>
      <ActionsNew text="Sync People" onClick={syncPeopleFn} />
      <Button className="mt-3" icon={<SearchOutlined />} onClick={showModal}>
        Search
      </Button>

      <div className="mt-1">
        {loading ? (
          <Loading />
        ) : !data.items.length ? (
          <NotData />
        ) : (
          <div className="table-responsive">
            <Table
              dataSource={data.items}
              rowKey="id_person"
              columns={[
                {
                  title: 'People',
                  key: 'none',
                  render: (record) => (
                    <HeaderAvatar {...record} suspended={record.suspended} />
                  ),
                },
                {
                  title: 'Department',
                  key: 'none',
                  render: (record) => <DepartmentElement {...record} />,
                },
                {
                  title: 'Suspended',
                  key: 'none',
                  render: (record) => <SuspendedElement {...record} />,
                },
              ]}
              pagination={pagination}
              onChange={handlePagination}
              onRow={(record) => ({
                onClick: () => onClick(record),
              })}
            />
          </div>
        )}
      </div>
      <QueryModalShare
        visible={isModalVisible}
        handleModal={handleCancel}
        handleQuerySearch={handleQuerySearch}
      />
    </div>
  );
};

export default PeopleListPage;

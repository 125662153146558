import React from 'react';
import { Typography } from 'antd';
// import { subscriptionList } from 'helpers/listDashboard';
import Subscription from './components/subscription';
import Uninstall from './components/uninstall';

const { Title } = Typography;

const SubscriptionPage = () => {
  // const [state, setState] = useState({
  //   list: subscriptionList,
  //   active: 'subscription',
  // });
  //
  // const onClick = (id: any) => {
  //   setState((prev) => {
  //     const list = prev.list.map((i) =>
  //       i.id === id ? { ...i, active: true } : { ...i, active: false },
  //     );
  //     return { ...prev, list, active: id };
  //   });
  // };

  return (
    <div
      className="animate__animated animate__fadeIn"
      style={{ minHeight: '75vh', position: 'relative' }}
    >
      <Title level={1} style={{ marginBlockEnd: 0 }}>
        Subscription
      </Title>
      <Subscription />
      <Uninstall />
    </div>
  );
};

export default SubscriptionPage;
